body[class*="page-template-home-"] {

	.section-get-started {
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: auto 431px;
		max-width: 1440px;
		margin: 0 auto;
		padding: 48px 20px 0;

		&:not(.section-get-started-b) {
			@include tablet() {
				background: #546168 !important;
				padding-bottom: 30px !important;
			}
		}

		@include media-min($mobile-max) {
			background-color: #fff;
			background-size: auto 556px;
			display: flex;
			flex-direction: column;
			padding: 137px 60px 0;
		}

		@include media-min($desktop-min) {
			background-color: #5a524e;
			background-size: cover !important;
			padding: 192px 20px 95px !important;
		}

		&[data-site="absn"] {
			background-size: cover;
			padding-bottom: 24px;
		}

		&[data-site="edd"] {
			background-size: cover;
			padding-bottom: 24px;
		}

		&[data-site="fnp"].section-get-started-b {
			background-color: #363e51;
			background-size: cover;
			background-position: right bottom;
			padding: 30px 15px 25px !important;
			position: relative;

			@include media-min(768px) {
				background-position: left 23% center;
				padding: 125px 0 20px !important;
			}

			@include media-min(991px) {
				background-position: left 35% center;
				margin: 93px auto 0;
				padding: 33px 0 20px !important;
			}

			@include media-min(1200px) {
				background-position: center;
			}
		}

		&-inside-middle {
			padding-bottom: 25px;
			padding-top: 25px;

			@include desktop {
				align-items: center;
				bottom: 0;
				display: flex;
				left: 50%;
				padding-bottom: 0;
				padding-top: 50px;
				position: absolute;
				top: 0;
				transform: translateX(-50%);
				width: 100%;
			}
		}

		&-inside {
			width: 100%;
		}

		&-description {
			color: #fff;
			max-width: 100%;
			padding: 0 0 36px;
			text-align: center;
			text-transform: uppercase;
			width: 100%;

			@include media-min($mobile-max) {
				padding: 0 0 44px;
			}

			@include media-min($desktop-min) {
				max-width: 360px;
				padding-top: 32px;
				padding-bottom: 32px;
				text-align: left;
			}

			h1 {
				font-size: 48px;
				line-height: 48px;
				margin-bottom: 11px;
				text-shadow: 0px 5px 10px rgba(0,0,0,0.5);

				@include media-min($desktop-min) {
					font-size: 70px;
					line-height: 75px;
				}

				@include media-min($desktop-extra-min) {
					font-size: 90px;
					line-height: 95px;
					margin-bottom: 43px;
				}
			}

			h2 {
				font: 21px/21px 'Trade Gothic LT W01 Bold', sans-serif;
				letter-spacing: 1px;
				margin: 0;
				text-shadow: 0px 5px 10px rgba(0,0,0,0.5);

				@include media-min($desktop-min) {
					font-size: 41px;
					line-height: 42px;
				}
			}

			span.months {
				background-color: $action-color;
				color: #000;
				font: 21px/1.2 'Trade Gothic LT W01 Bd CnNo-20', sans-serif;
				letter-spacing: 1px;
				margin: 0;
				padding: 0 8px;
				white-space: nowrap;
				text-shadow: none;

				@include media-min($desktop-min) {
					display: inline-block;
					font-size: 41px;
					line-height: 1.2;
					margin: 12px 0 0;
					padding: 0 17px 0 10px;
				}
			}
		}

		&-description-b {
			color: #fff;
			max-width: 100%;
			padding: 0 0 36px 0;
			text-align: center;
			text-transform: uppercase;
			width: 100%;

			@include media-min($desktop-min) {
				padding: 16px 20px 0 0;
				text-align: left;
			}

			h1 {
				font-size: 24px;
				line-height: 32px;
				margin-bottom: 11px;
				padding-left: 6px;
				padding-right: 6px;

				@include media-min($tablet-min) {
					font-size: 38px;
					line-height: 45px;
					padding-right: 0;
				}

				@include media-min($desktop-extra-min) {
					font-size: 58px;
					line-height: 65px;
					margin-bottom: 54px;
				}

				.degree {
					color: #000;
					display: block;
					font-size: 70%;
					line-height: 1.3;
					padding: 0 0 12px;
					width: 100%;

					@include media-min($desktop-extra-min) {
						padding: 0 0 24px;
					}
				}

				.fill {
					background: #fbcb6c;
					color: #010100;
					margin-left: -6px;
					padding: 0 6px;
					text-shadow: initial;
				}
			}

			h2 {
				font-size: 20px;
				line-height: 26px;
				margin-bottom: 11px;
				padding-left: 6px;
				padding-right: 6px;

				@include media-min($tablet-min) {
					font-size: 32px;
					line-height: 38px;
					padding-right: 0;
				}

				@include media-min($desktop-extra-min) {
					font-size: 52px;
					line-height: 58px;
					margin-bottom: 42px;
				}
			}

			&[data-site="fnp"] {
				max-width: 468px;
				position: relative;
				z-index: 99;

				@include tablet {
					max-width: 100%;
				}

				h1 {
					margin-bottom: 8px;
					padding-left: 0;
					padding-right: 0;
					text-shadow: 0 8px 9px rgba(0,0,0,.15);

					@include media-min($desktop-extra-min) {
						font-size: 92px;
						line-height: 93px;
						margin: 0 0 50px;
					}
				}

				h2 {
					color: #fff;
					font-size: 20px;
					line-height: 28px;
					margin: 0 0 10px;
					padding: 0;
					text-shadow: 0 8px 9px rgba(0,0,0,.15);

					@include media-min($tablet-min) {
						font-size: 28px;
						line-height: 34px;
						margin: 0 0 15px;
					}

					@include media-min($desktop-extra-min) {
						font-size: 34px;
						line-height: 40px;
						margin: 0 0 20px;
					}

					span {
						background: #e5b54e;
						color: #000;
						font-size: 18px;
						padding: 0 12px;
						text-shadow: initial;

						@include media-min($tablet-min) {
							font-size: 25px;
						}

						@include media-min($desktop-extra-min) {
							font-size: 29px;
						}
					}
				}

				.fill {
					background: #e5b54e;
					color: #000;
					margin: 0;
					padding: 0 12px;
					text-shadow: initial;
				}

				ul:last-child,
				ol:last-child {
					margin-bottom: 0;
				}

				li {
					color: #fff;
					font-family: 'Trade Gothic LT W01 Bold', sans-serif;
					margin: 0;
					text-shadow: 0 3px 9px rgba(0,0,0,.36);

					@include media-min($tablet-min) {
						font-size: 23px;
						line-height: 48px;
						padding: 0 0 0 23px;
					}

					&::before {
						background: #fff;
						box-shadow: 0 3px 9px rgba(0,0,0,.36);
						height: 6px;
						top: 19px;
						width: 6px;
					}
				}

				p {
					color: #fff;
					font-family: 'Trade Gothic LT W01 Bold', sans-serif;
					text-shadow: 0 3px 9px rgba(0,0,0,.36);

					@include media-min($tablet-min) {
						font-size: 23px;
						line-height: 48px;
					}
				}
			}

			&[data-site="edd"] {
				li {
					&::before {
						background: #fff;
					}
				}
			}

			&[data-site="absn"] {

				h1 {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					margin-bottom: 8px;
					padding-left: 0;
					padding-right: 0;

					@include desktop {
						justify-content: flex-start;
					}
				}

				h2 {
					color: #000;
				}
				
				li {
					color: #000;
				}

				.fill {
					background: #363e51;
					color: #fff;
					display: inline-block;
					margin-bottom: 10px;
				}
			}

			ul,
			ol {
				letter-spacing: .5px;
				margin: 0 0 45px;

				@include tablet() {
					margin-bottom: 11px;
				}
			}

			p {
				font-size: 16px;
				line-height: 22px;
				margin: 0 0 12px;
				letter-spacing: .5px;
				text-transform: uppercase;

				@include media-min($tablet-min) {
					font-size: 23px;
					line-height: 27px;
					margin: 0 0 17px;
				}

				strong {
					font-family: $default-font;
					font-weight: 600;
				}
			}

			li {
				font-size: 16px;
				line-height: 22px;
				margin: 0 0 8px;
				position: relative;
				text-transform: uppercase;

				@include media-min($tablet-min) {
					font-size: 23px;
					line-height: 27px;
					margin: 0 0 12px;
					padding: 0 0 0 14px;
				}

				&::before {
					background: #000;
					border-radius: 50%;
					content: '';
					height: 5px;
					left: 0;
					position: absolute;
					top: 11px;
					width: 5px;

					@include tablet() {
						display: none;
					}
				}
			}
		}

		&-action {
			margin-left: auto;
			width: 565px;

			@include media($desktop-min) {
				width: 100%;
			}

			&[data-site="fnp"],
			&[data-site="absn"],
			&[data-site="edd"] {
				max-width: 565px;
				width: 100%;

				@include media($desktop-min) {
					max-width: 100%;
					width: 100%;
				}

				form {
					box-shadow: 0 35px 35px rgba(#030303,.17);
				}

				.section-get-started-action-form {

					.form-control-wide {
						@include media-min(768px) {
							max-width: calc(100% / 2 - 5px)!important;
						}

						@include media-min(991px) {
							max-width: 100%!important;
						}
					}

					.form-control:nth-child(n+5) {
						@include media-min(768px) {
							max-width: calc(100% / 2 - 5px);
						}

						@include media-min(991px) {
							max-width: calc(50% - 10px);
						}
					}

					.form-control {
						@include media-min(768px) {
							max-width: calc(50% - 5px);
						}

						@include media-min(991px) {
							max-width: calc(50% - 10px);
						}
					}
				}
			}

			&-disclaimer p {
				font-size: 11px !important;
				line-height: 15px !important;
				text-align: left;
				margin: 12px 0 2px 0 !important;
				padding: 0;
			}
		}
	}

	.section-statistic {
		padding: 52px 20px 85px;

		@include media-min($mobile-min) {
			padding-bottom: 85px;
			padding-top: 52px;
		}

		@include media-min($tablet-min) {
			padding-bottom: 97px;
			padding-top: 64px;
		}

		@include media-min($desktop-min) {
			padding-bottom: 140px;
			padding-top: 105px;
		}

		.row {
			min-width: 100%;
		}

		h2 {
			color: #262626;
			font-size: 30px;

			@include media-min($mobile-max) {
				font-size: 36px;
				line-height: 36px;
			}

			@include media-min($desktop-min) {
				font-size: 42px;
				line-height: 42px;
			}

			& + p {
				display: block;
				line-height: 30px;
				margin-top: 23px;
				max-width: 650px;

				@include media($desktop-min) {
					font-size: 16px;
				}
			}
		}

		& > .d-flex {
			padding-left: 20px;
			padding-right: 40px;

			@include media($tablet-min) {
				flex-direction: column;
			}

			@include media($desktop-extra-min) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&-item {
			margin-top: 34px;
			max-width: 470px;

			@include media($tablet-min) {
				max-width: 100%;
			}

			@include media($desktop-extra-min) {
				justify-content: flex-start !important;
			}

			span {
				margin-right: 58px;
				width: 103px;

				@include media($tablet-min) {
					min-width: 70px;
				}

				@include media($desktop-extra-min) {
					margin-right: 33px;
					width: 70px;
				}
			}

			&-data {
				max-width: 100%;

				@include media-min($mobile-max) {
					max-width: 310px;
				}

				h3 {
					color: $action-color-dark;
					font-family: 'Trade Gothic LT W01 Light', sans-serif;
					font-size: 40px;
					font-weight: lighter;
					letter-spacing: 5px;
					line-height: 1;
					margin-bottom: 7px;

					@include media-min(360px) {
						font-size: 52px;
					}

					@include media-min($desktop-extra-min) {
						font-size: 78px;
					}
				}

				p {
					@include media($desktop-extra-min) {
						font-size: 16px;
					}
				}
			}

			&:nth-child(3) {
				span {
					max-width: 90px;
				}
			}

			&:nth-child(4) {
				span {
					margin-left: 6px;
					max-width: 96px;
				}
			}
		}
	}

	.section-video {
		overflow: hidden;
	}

	.section-quote {
		background: #f2f2f2;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 64px 20px;
		text-align: center;

		@include media(767px) {
			background-image: none !important;
		}

		@include media-min($mobile-max) {
			padding: 78px 60px;
		}

		@include media-min($desktop-min) {
			background-color: #c1c1c1;
			padding: 132px 0;
			text-align: center;
		}

		.row {
			min-width: 100%;
		}

		p {
			background-color: #fff;
			box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
			font: 30px/40px 'Sabon MT W01 Italic', sans-serif;
			max-width: 731px;
			padding: 54px 35px;

			@include media-min($mobile-max) {
				font: 24px/36px 'Sabon MT W01 Italic', sans-serif;
				padding: 69px 75px 76px;
			}

			@include media-min($desktop-min) {
				box-shadow: 0 50px 70px 0 rgba(0, 0, 0, 0.14);
				font-size: 32px;
				padding: 84px 90px 94px 88px;
			}
		}
	}

	.section-virtual-tour {
		padding-bottom: 99px;
		position: relative;
		z-index: 9;

		@include media($mobile-min) {
			padding: 0 20px 53px;
		}

		@include media($tablet-min) {
			padding-bottom: 50px;
		}

		.row {
			min-width: 100%;
		}

		h2 {
			font-size: 30px;
			margin: 0 0 23px;
			text-align: center;

			@include media-min($mobile-max) {
				font-size: 36px;
				line-height: 36px;
			}

			@include media-min($desktop-min) {
				font-size: 42px;
				line-height: 42px;
			}
		}

		img {
			display: block;
			height: auto;
			max-width: 800px;
			width: 100%;
		}
	}

	.section-details {
		padding-bottom: 146px;
		padding-top: 140px;
		position: relative;
		z-index: 9;

		@include media($mobile-min) {
			padding: 50px 20px 53px;
		}

		@include media($tablet-min) {
			padding-bottom: 50px;
			padding-top: 53px;
		}

		@include media($desktop-min) {
			flex-direction: column;
			padding-bottom: 99px;
			padding-top: 75px;
		}

		.row {
			min-width: 100%;
		}

		&-description {

			@include media($desktop-min) {
				max-width: 100%;
				text-align: center;
			}

			h2 {
				font-size: 30px;
				margin: 0 0 23px;

				@include media-min($mobile-max) {
					font-size: 36px;
					line-height: 36px;
				}

				@include media-min($desktop-min) {
					font-size: 42px;
					line-height: 42px;
					margin: 0;
				}
			}

			p {
				font-size: 24px;
				margin-top: 47px;

				@include media($desktop-min) {
					font-size: 16px;
					line-height: 30px;
					margin: 0 0 47px;
				}
			}
		}

		&-list {
			padding-left: 15px;

			@include media-min($desktop-min) {
				padding-left: 80px;
			}

			@include media-min($desktop-extra-min) {
				padding-left: 130px;
			}

			&-item {
				flex-direction: column;
				margin-top: 35px;
				text-align: center;

				@include media-min($mobile-max) {
					flex-direction: row;
					text-align: left;
				}

				&:first-child {
					margin-top: 0;
				}

				&-icon {
					background-color: #546168;
					border-radius: 50%;
					height: 119px;
					margin: auto auto 23px;
					min-width: 119px;
					width: 119px;

					@include media-min($mobile-max) {
						margin: 0 43px 0 0;
					}

					@include media-min($desktop-min) {
						margin: 0 56px 0 0;
					}
				}

				div {
					max-width: 100%;
				}

				&-title {
					color: $action-color-dark;
					display: block;
					font: 26px/30px 'Trade Gothic LT W01 Bold',sans-serif;
					letter-spacing: 1px;
					margin: 0 0 20px;

					@include media-min($mobile-max) {
						font-size: 30px;
						margin: 0;
					}
					
					a, a:hover, a:focus, a:visited {
						color: $action-color-dark;
						text-decoration: underline;
					}
				}

				p {
					font-size: 16px;
					line-height: 30px;
					margin-top: 14px;
					max-width: 489px;

					@include media($desktop-min) {
						max-width: 100%;
					}
				}
			}
		}

		&.two-columns {
			@include media-min($desktop-min) {
				padding-top: 100px;
			}

			.section-details-description {
				p {
					@include media-min($desktop-min) {
						font-size: 26px;
						line-height: 30px;
						margin-bottom: 45px;
						margin-top: 25px;
					}
				}
			}

			.section-details-list {
				@include media-min($desktop-min) {
					column-count: 2;
					column-gap: 48px;
					margin-top: 35px;
					padding-left: 15px;
				}

				@include media-min($desktop-extra-min) {
					margin-left: -3%;
					min-width: 106% !important;
				}

				&-item {
					@include media-min($desktop-min) {
						break-inside: avoid-column;
					}

					&-icon {
						@include media-min($desktop-min) {
							margin: 0 40px 0 0;
						}
					}
				}
			}
		}
	}

	.section-in-touch {
		background-color: #bcb59f;
		background-repeat: no-repeat;
		max-width: 1440px;
		padding: 67px 20px 72px;

		@include media(767px) {
			background-image: none !important;
		}

		@include media-min($mobile-max) {
			background-color: none;
			background-position: center -145px;
			background-size: auto 660px;
			max-height: 513px;
			padding: 85px 20px 95px;
		}

		@include media-min($desktop-min) {
			background-position: center 0;
			background-size: cover;
			max-height: inherit;
			padding: 114px 20px 112px;
		}

		&-action {
			background-color: #fff;
			box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
			padding: 35px 30px 30px;
			text-align: left;

			@include media-min(360px) {
				padding: 45px 50px 40px;
			}

			@include media-min($tablet-min) {
				box-shadow: none;
				margin: 0;
				max-width: 100%;
				padding: 45px 50px 42px;
				text-align: center;
			}

			@include media-min($desktop-min) {
				margin: 0;
				max-width: 335px;
				padding: 47px 55px 46px 59px;
				text-align: left;
			}

			&-title {
				display: block;
				font: 28px/34px 'Trade Gothic LT W01 Bold',sans-serif;
				letter-spacing: 1px;
				margin: 0;
				text-transform: uppercase;

				@include media($mobile-min) {
					font-size: 26px;
				}
			}

			p {
				font-size: 16px;
				margin-top: 20px;
			}

			.btn-primary {
				margin-top: 10px;
				min-width: 221px;

				@include media($tablet-min) {
					background: rgba(218, 166, 73, .8);
					width: 100%;
				}
			}
		}
	}

	.section-pathways {
		padding: 0 15px 100px;

		.row {
			min-width: 100%;
		}

		h2 {
			color: #262626;
			font-size: 30px;

			@include media-min($mobile-max) {
				font-size: 36px;
				line-height: 36px;
			}

			@include media-min($desktop-min) {
				font-size: 42px;
				line-height: 42px;
			}

			& + p {
				display: block;
				line-height: 30px;
				margin-top: 23px;
				max-width: 650px;

				@include media($desktop-min) {
					font-size: 16px;
				}
			}
		}

		&-header {
			padding: 0 15px 24px;
			width: 100%;
		}

		&-tabs {

			&-nav {
				align-items: center;
				background: $action-color;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				text-align: center;

				@include tablet {
					flex-direction: column;
				}

				span {
					align-items: center;
					align-self: stretch;
					cursor: pointer;
					display: flex;
					font-size: 18px;
					font-family: 'Trade Gothic LT W01 Bold', sans-serif;
					font-weight: 600;
					justify-content: center;
					padding: 28px 12px;
					position: relative;
					text-transform: uppercase;
					transition: background .25s ease;
					width: 100%;

					@include tablet {
						border-bottom: 2px solid #fff;
						font-size: 16px;
						padding: 24px;

						&:last-child {
							border: none;
						}
					}

					&::after {
						border-top: 10px solid transparent;
						border-left: 14px solid #fff;
						border-bottom: 10px solid transparent;
						content: '';
						left: 0;
						height: 0;
						opacity: 0;
						position: absolute;
						top: calc(50% - 10px);
						transition: opacity .25s ease;
						width: 0;

						@include desktop {
							border-bottom: none;
							border-top: 14px solid #fff;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							left: calc(50% - 10px);
							top: 0;
						}
					}

					&.current,
					&:hover {
						background: #BA8426;

						&::after {
							opacity: 1;
						}
					}
				}
			}

			&-wrapper {
				background: #f3f3f3;
			}
		}

		&-tab {
			display: none;
			flex-direction: row;
			padding: 36px;

			@include tablet {
				flex-direction: column;
			}

			li {
				font-size: 18px;
				line-height: 24px;
				margin: 0 0 8px;
				padding: 0 0 0 20px;
				position: relative;

				&:before{
					background: #daa649;
					border-radius: 6px;
					content: '';
					height: 6px;
					left: 0;
					position: absolute;
					top: 9px;
					width: 6px;
				}
			}

			&-description {
				@include desktop {
					padding-right: 36px
				}

				h3 {
					font-size: 20px;
					line-height: 1.2;
					margin: 0 0 12px;
				}

				h4 {
					font-family: $default-font;
					font-size: 28px;
					font-weight: 600;
					letter-spacing: initial;
					line-height: 1.5;
					margin: 0 44px 44px 0;
					text-transform: initial;

					@include tablet {
						font-size: 20px;
					}

					@include desktop {
						margin: 24px 44px 44px;
					}
				}

				a {
					color: #ba8426;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			&-requirements {
				flex: 1 0 250px;

				@include tablet {
					flex: initial;
					margin: 24px auto 0;
					width: 100%;
				}
			}

			&-requirement {
				align-self: flex-start;
				background: #074f74;
				margin: 0 0 24px;
				padding: 24px;

				ul {
					margin: 0 0 12px;
				}

				li {
					color: #fff;
					font-size: 16px;

					&:before{
						background: #fff;
					}
				}

				h5 {
					color: #fff;
					font-family: $default-font;
					font-size: 20px;
					letter-spacing: initial;
					line-height: 1.5;
					margin: 0 0 32px;
					position: relative;
					text-align: center;

					&::after {
						border-bottom: 1px solid #ba8426;
						bottom: -15px;
						content: '';
						left: 30%;
						position: absolute;
						width: 40%;
					}
				}

				.btn {
					font-size: 20px;
					min-height: initial;
					padding: 8px 10px;
					width: 100%;
				}
			}
		}
	}
}
