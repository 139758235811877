.page-template-thank-you {
	.thank-you {
		padding: 40px 0;
		text-align: center;

		@include media-min($tablet-min) {
			padding: 120px 0 40px;
		}

		.container {
			padding-left: 15px;
			padding-right: 15px;
		}

		&-description {

			h3 {
				color: #000;
				font-family: 'Trade Gothic LT W01 Bold', sans-serif;
				font-size: 60px;
				font-weight: 600;
				text-transform: uppercase;

				@include media-min($tablet-min) {
					font-size: 84px;
				}
			}

			p {
				color: #000;
				font-family:  'Trade Gothic LT W01 Bd CnNo-20', sans-serif;
				font-size: 20px;
				line-height: 1.5;
				font-weight: 600;

				@include media-min($tablet-min) {
					font-size: 28px;
				}
			}
		}

		&-posts {
			background: #f1f1f1;
			margin: 30px 0;
			padding: 40px 0;

			.container {
				display: flex;
				flex-wrap: wrap;
			}
		}

		&-post {
			align-items: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			display: flex;
			justify-content: center;
			height: 275px;
			margin: 0 0 24px;
			padding: 12px;
			position: relative;
			text-align: center;
			width: 100%;

			@include media-min($desktop-min) {
				margin: 0 12px;
				width: calc(100% / 3 - 24px);
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::after {
				background: rgba(#000,.5);
				bottom: 0;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}

			h4 {
				font-size: 1.2rem;
				position: relative;
				text-transform: initial;
				z-index: 9;

				@include media-min($desktop-extra-min) {
					font-size: 1.5rem;
				}
			}

			a {
				background: url(../img/icon-arrow.svg) no-repeat center bottom 7px;
				background-size: 32px 32px;
				color: #fff;
				display: inline-flex;
				font-weight: 700;
				padding: 20px 0 60px;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&-social {
			text-align: center;

			&-title {
				margin: 0 0 12px;
			}

			&-links {
				display: flex;
				justify-content: center;
			}

			a {
				align-items: center;
				display: flex;
				justify-content: center;
				margin: 0 8px;
				max-width: 24px;

				img {
					display: block;
					height: auto;
					width: 24px;
				}
			}
		}
	}
}