.alignleft {
	float: left;
	margin: 0 70px 50px 0;
}

.alignright {
	float: right;
	margin: 0 0 50px 70px;
}

.aligncenter {
	display: block;
	margin: 0 auto 50px;
}

p{
	.alignleft {
		margin-top: 47px;
	}

	.alignright {
		margin-top: 47px;
	}

	.aligncenter {
		margin-top: 47px;
	}
}

@keyframes animate {
	from {
		transform: scale3d(1, 1, 1);
	}
	50% {
		transform: scale3d(1.15, 1.15, 1.15);
	}
	to {
		transform: scale3d(1, 1, 1);
	}
}
.animation {
	animation-name: animate;
}

.wrapper {
	position: relative;
}

.container {
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

.container-fluid {
	padding-left: calc((100% - 1140px) / 2);
	padding-right: calc((100% - 1140px) / 2);
	position: relative;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}