.section-get-started-action-form {
	box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
	background-color: #fff;
	padding: 28px 15px 20px;

	@include media-min(360px) {
		padding: 33px 36px 20px;
	}

	@include media-min($mobile-max) {
		box-shadow: none;
	}

	@include media-min($desktop-min) {
		padding: 33px 50px 20px;
	}

	fieldset {
		border:none;
	}

	.title-form{
		display: block;
		font: 27px/27px 'Trade Gothic LT W01 Bold', sans-serif;
		margin: 0 0 5px;
		color:#000;

		@include media-min($mobile-min) {
			margin: 0 0 20px;
		}

		@include media-min($desktop-min) {
			font-size: 36px;
			line-height: 36px;
			margin: 0 0 3px;
		}
	}

	.thank-you-text {
		font-family: 'Trade Gothic LT W01 Bold',sans-serif;
		font-size: 25px;
		letter-spacing: 1px;
		line-height: 30px;
		margin: 0 0 13px;

		@include media-min($desktop-min) {
			font-size: 34px;
			line-height: 39px;
		}
	}

	.input-group {
		padding-top: 2px;
	}

	.form-control {
		background-color: #fcfcfc;
		border-color: #ccc;
		border-radius: 0;
		margin-top: 10px;
		max-width: 100%;
		padding: 13px 22px;
		width: 100%;

		&:nth-child(n + 5) {
			max-width: 100%;
		}

		@include placeholder-color(#666);

		@include media-min($mobile-max) {
			max-width: calc(50% - 10px);
			width: 100%;

			&:nth-child(n + 5) {
				max-width: calc(100% / 3 - 5px);
			}
		}

		@include media-min($desktop-min) {
			margin-top: 20px;
			max-width: calc(50% - 10px);
			padding: 12px 22px;

			&:nth-child(n + 5) {
				max-width: calc(50% - 10px);
			}

		}

		&-wide {
			max-width: 100% !important;

			@include media-min($mobile-max) {
				max-width: calc(100% / 3 - 5px) !important;
			}

			@include media-min($desktop-min) {
				max-width: 100% !important;
			}
		}
	}

	select.form-control {
		padding: 6px 19px;

		optgroup {
			display: none;
		}
	}

	.btn-primary[type=submit] {
		display: block;
		margin-top: 24px;
		min-width: 237px;
		width: 100%;

		@include media-min($mobile-max) {
			width: auto;
		}

		@include media($desktop-min) {
			margin-top: 20px;
		}
	}
}

.section-content-form {
	background: #fff;
	border-top: 4px solid #fbcb6c;
	box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
	margin: 0 0 50px;
	min-width: 100%;
	padding: 25px 15px 26px;
	position: relative;
	z-index: 1;

	@include media-min($mobile-max) {
		float: none !important;
		min-width: 720px;
		padding: 25px 36px 40px;
	}

	@include media-min($desktop-min) {
		box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
		float: right !important;
		margin: 12px 0 50px 80px;
		max-width: 360px;
		min-width: 360px;
		padding: 25px 42px 25px;
		width: 360px;
	}

	.input-group {
		display: flex;
		flex-wrap: wrap;

		@include media-min($mobile-max) {
			flex-direction: row !important;
			justify-content: space-between;
		}

		@include media-min($desktop-min) {
			flex-direction: column !important;
			justify-content: space-between;
		}

		.form-control{
			background: none;
			height: 50px;
			margin: 10px 0 0;
			width: 100%;

			@include media-min($mobile-max) {
				max-width: calc(50% - 5px);
			}

			@include media-min($desktop-min) {
				max-width: 100% !important;
			}

			&-wide {
				@include media-min(768px) {
					max-width: 100%;
				}
			}
		}

		select.form-control {
			optgroup {
				display: none;
			}
		}
	}

	p{
		font-size: 1rem !important;
		line-height: 1.5 !important;
		padding: 0 20px;
		text-align: center;
	}

	.title-form{
		display: block;
		font: 21px/32px 'Trade Gothic LT W01 Bold No-_2', sans-serif;
		letter-spacing: .2px;
		margin: 0 0 12px !important;
		color:#000;
	}

	.thank-you-text {
		font-size: 21px;
		line-height: 30px;
		margin: 0 0 12px;
	}

	button[type='submit']{
		display: block;
		margin-top: 24px;
		width: 100%;

		@include media-min($mobile-max) {
			margin: 24px auto 0;
			width: 237px;
		}

		@include media-min($desktop-min) {
			width: 100%;
		}
	}

	.btn-group-toggle {
		fieldset {
			display:flex;
			width:100%;
		}
		input[type=checkbox], input[type=radio] {
			position:absolute;
			left: -9999px;
			opacity:0;
			visibility: hidden;
		}
	}
	fieldset {
		border:none;
		padding:0;
		margin:0;
		legend {
			font-size: 1em;
			line-height: 1em;
			width: 100%;
		}
	}
}

.section-schedule-content-form{
	padding: 30px 25px 20px;

	@include media-min($mobile-max) {
		padding: 61px 55px 20px;
	}

	.thank-you-text {
		font-family: 'Trade Gothic LT W01 Bold',sans-serif;
		font-size: 28px;
		letter-spacing: -1px;
		line-height: 36px;
		margin: 0 0 6px;
	}

	.input-group {
		padding-top: 2px;
	}

	.form-control {
		background: none;
		border-color: #ccc;
		border-radius: 0;
		margin-top: 10px;
		max-width: 100%;
		padding: 13px 22px;
		width: 100%;

		@include placeholder-color(#666);

		@include media-min($mobile-max) {
			max-width: calc(50% - 10px);
			width: 100%;
		}

		@include media-min($desktop-min) {
			margin-top: 20px;
			padding: 17px 22px;
		}
	}

	select.form-control {
		padding: 6px 19px;
		max-width: 100%;

		optgroup {
			display: none;
		}
	}

	button[type='submit']{
		display: block;
		height: 55px;
		margin-top: 37px;
		width: 100%;

		@include media-min($mobile-max) {
			height: 65px;
			margin: 37px auto 0;
			max-width: 310px;
			width: 100%;
		}
	}
}

.label-hidden{
	display: none !important;
}

.thank-you-text {
	display: none;
	font-family: 'Trade Gothic LT W01 Bold No-_2', sans-serif;
	letter-spacing: .2px;
}

.disclaimer p {
	font-size: 11px !important;
	line-height: 15px !important;
	text-align: left;
	margin: 12px 0 2px 0 !important;
	padding: 0;
}
