body.page-template-lcform {

	.lcconfirm {
		padding: 50px 0;
	}

	.section-schedule{
		padding: 0;
		position: relative;

		@include media-min($mobile-max) {
			padding: 5px 0 0 0;
		}

		&-content {
			background: #fff;
			box-shadow: 0 0 140px rgba(0,0,0,.13);
			flex-wrap: wrap;
			margin: 0;
			min-height: 500px;

			&-form{

				h3{
					font-size: 28px;
					letter-spacing: -1px;
					line-height: 28px;
					margin: 0 0 6px;
				}

				.thank-you-text {
					margin: 165px 0;
				}

				input[name="email"] {
					max-width: 100%;
				}

				select {
					height: auto !important;
				}

				.form-control-wide {
					max-width: 100% !important;
				}

				.subtitle-form {
					display: none;
					font: 16px/24px 'Trade Gothic LT W01 Bold No-_2', sans-serif;
					letter-spacing: .2px;
					color:#000;

					&.show {
						display: block;
					}
				}
			}
		}
	}

	.header-main-logo {
		margin: 0 auto;
	}

	.footer-main {
		padding-bottom: 35px;
	}
}