.btn {
	border: 0;
	border-radius: 0;
	font-family: 'Trade Gothic LT W01 Bd CnNo-20', sans-serif;
	font-size: 20px;
	padding: 10px 35px;
	text-transform: uppercase;

	&.btn-primary {
		background-color: $action-color-light;
		color: #000;
		font-size: 24px;
		min-height: 65px;
		padding: 11px 50px;

		&:hover,
		&:active {
			background-color: $action-color !important;
			border-color: $action-color !important;
		}
	}

	&.btn-outline {
		background-color: #fff;
		border: 2px solid $action-color;
		color: $default-color-text;
	}
}


.btn-group-toggle {

	.btn-primary {
		min-height: 0;

		&:not(:disabled):not(.disabled) {
			&.active, &.active:hover {
				background-color: #546168 !important;
				border-color: #546168 !important;
///				&.focus {
///					box-shadow: 0 0 0 .2rem $action-color
///				}
			}
		}

		&.error-input{
			box-shadow: 0 0 3px 1px #f00;
		}
	}
}
