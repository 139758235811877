.form-steps {
	background: #fff;
	padding: 30px 20px 20px;

	@include media-min(1200px) {
		padding: 30px 40px 20px;
	}

	&.auto-height {
		height: auto;
	}

	&-title {
		color: #000;
		display: block;
		font-family: 'Trade Gothic LT W01 Bold', sans-serif;
		font-weight: 700;
		font-size: 27px;
		line-height: 27px;
		margin: 0 0 20px;
		text-align: center;
		text-transform: uppercase;

		@include media-min(1200px) {
			font-size: 36px;
			line-height: 43px;
			margin: 0 0 25px;
		}
	}

	.subtitle-form{
		display: block;
		font: 16px/24px $default-font;
		margin: -10px 0 10px;
		color:#000;
	}

	&-header {
		display: flex;
		justify-content: space-between;
		margin: 0 0 20px;
		padding: 0 0 31px;
		position: relative;
		text-align: center;

		&::after {
			background: #D4D4D4;
			bottom: 0;
			content: '';
			height: 1px;
			left: -20px;
			position: absolute;
			right: -20px;

			@include media-min(1200px) {
				left: -40px;
				right: -40px;
			}
		}

		&-item {
			background: url("../img/icon-current.svg") no-repeat center;
			background-size: 0 0;
			border-bottom: 5px solid #D5D5D5;
			color: #D4D4D4;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			padding: 5px 0;
			transition: all .25s ease;
			width: calc(50% - 4px);

			&.current {
				border-color: #FACA73;
				color: #000;
			}

			&.completed {
				background-size: 18px 18px;
				color: transparent;
			}
		}
	}

	&-degree {
		padding: 0 0 30px;
		text-align: center;

		@include tablet {
			padding: 0 0 10px;
		}

		&-title {
			color: #000;
			font-weight: 400;
			font-size: 21px;
			line-height: 25px;
			margin: 0 0 30px;

			@include tablet {
				margin: 0 0 20px;
			}
		}

		&-items {
			display: flex;
			justify-content: space-between;
		}

		&-item.btn.btn-primary {
			border: 3px solid #fbcb6c;
			cursor: pointer;
			min-height: 50px;
			padding: 7px 50px;
			width: calc(50% - 15px);
			text-transform: uppercase;

			@include tablet {
				padding: 7px 15px;
				width: calc(50% - 5px);
			}

			&.current {
				background-color: #fff;
				border-color: #daa649;

				span {
					background-size: 18px 18px;
					padding: 0 28px 0 0;
				}

				&:hover {
					background-color: #fff !important;
				}
			}

			&.error {
				border-color: #f00 !important;
			}

			span {
				background: url("../img/icon-current.svg") no-repeat right center;
				background-size: 0;
			}
		}
	}

	&-fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&-footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 0 13px;

		@include media-min(768px) {
			flex-direction: row;
		}

		.btn {
			align-items: center;
			display: flex;
			font-weight: 700;
			font-size: 25px;
			line-height: 25px;
			min-height: 56px;
			padding: 15px 26px 15px 33px;

			@include tablet {
				justify-content: center;
			}

			svg {
				display: block;
				margin-left: 20px;
			}
		}

		.form-button-prev {
			align-items: center;
			border: 3px solid transparent;
			display: flex;
			font-weight: 700;
			font-size: 25px;
			line-height: 25px;
			min-height: 56px;
			padding: 15px 33px 15px 0;
			transition: all .25s ease;

			@include tablet {
				margin-bottom: 15px;
			}

			&:hover {
				border-color: #fbcb6c
			}

			svg {
				display: block;
				margin-right: 20px;
			}
		}
	}

	.thank-you-text {
		font-family: 'Trade Gothic LT W01 Bold',sans-serif;
		font-size: 25px;
		letter-spacing: 1px;
		line-height: 30px;
		margin: -15px 0 13px;

		@include media-min($desktop-min) {
			font-size: 34px;
			line-height: 39px;
		}

		@include media-min(1200px) {
			margin-top: -22px;
		}
	}

	.form-body-step {
		display: none;

		&-1 {
			@include desktop {
				padding: 10px 0 0;
			}
		}
	}

	.form-button-next {
		display: block;
		min-height: 56px;
		margin: 50px auto 17px;
		max-width: 350px;
		width: 100%;

		@include tablet {
			justify-content: center;
			margin: 20px auto 15px;
		}
	}

	.disclaimer p {
		font-size: 11px !important;
		line-height: 15px !important;
		text-align: left;
		margin: 12px 0 2px 0 !important;
		padding: 0;
	}

	select,
	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: 1px solid #9B9EA2;
		border-radius: 3px;
		color: #495057;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		height: 57px;
		padding: 0 21px;
		margin: 0 0 30px;
		width: 100%;

		@include tablet {
			margin: 0 0 10px;
		}

		&.half {
			@include media-min(768px) {
				width: calc(50% - 15px);
			}
		}
	}

	.consent-wrapper {
		display: block;
		padding: 0 0 42px;

		input[type="checkbox"] {
			opacity: 0;
			height: 1px;
			position: absolute;
			width: 1px;

			&:checked + label::before {
				background-size: 14px 11px;
			}
		}

		label {
			color: #495057;
			display: block;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			margin: 0;
			padding: 0 0 0 26px;
			position: relative;

			&::before {
				background: url("../img/icon-checkbox.svg") no-repeat center;
				background-size: 0 0;
				border: 1px solid #9B9EA2;
				border-radius: 3px;
				content: '';
				cursor: pointer;
				height: 21px;
				left: 0;
				position: absolute;
				top: 2px;
				transition: all .25s ease;
				width: 21px;
			}

			a {
				color: #006ce0;
			}
		}
	}

	.select {
		background: #fff;
		position: relative;
		width: 100%;

		@include tablet {
			margin: 0 0 10px;
		}

		&::before {
			background: url("../img/icon-select.svg") no-repeat;
			background-size: contain;
			content: '';
			height: 5px;
			position: absolute;
			right: 26px;
			top: 25px;
			width: 10px;
			z-index: 1;
		}

		select {
			background: none;
			line-height: 18px;
			padding-right: 40px;
			margin: 0;
			min-height: 57px;
			white-space: normal;

			&.error {
				border-color: #f00;
			}
		}
	}

	.section-content-form & {
		padding: 0;

		@include media-min(768px) {
			height: auto;
		}

		@include media-min(1200px) {
			padding: 0;
		}

		.form-body-step-1 {
			padding: 0;
		}

		.select,
		input[type=email],
		input[type=tel],
		input[type=text] {
			margin-bottom: 15px;
		}

		.form-button-next {
			margin: 0 auto 15px;
		}

		.form-steps-footer {
			flex-direction: column;
		}

		.form-button-prev {
			font-size: 20px;
			justify-content: center;
			margin-top: 10px;
		}

		.form-steps-footer .btn svg {
			display: inline;
		}

		button[type=submit] {
			margin-top: 15px;
		}

		.consent-description {
			display: block;
			font-size: 13px;
			line-height: 1.5;

			input {
				display: inline;
				height: auto;
				width: auto;
			}
		}
	}
}