.main-nav {

	&-top {
		background: rgba(80,78,76,1);
		background: -moz-linear-gradient(top, rgba(80,78,76,1) 0%, rgba(116,111,107,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(80,78,76,1)), color-stop(100%, rgba(116,111,107,1)));
		background: -webkit-linear-gradient(top, rgba(80,78,76,1) 0%, rgba(116,111,107,1) 100%);
		background: -o-linear-gradient(top, rgba(80,78,76,1) 0%, rgba(116,111,107,1) 100%);
		background: -ms-linear-gradient(top, rgba(80,78,76,1) 0%, rgba(116,111,107,1) 100%);
		background: linear-gradient(to bottom, rgba(80,78,76,1) 0%, rgba(116,111,107,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#504e4c', endColorstr='#746f6b', GradientType=0 );
		display: none;

		@include media-min($mobile-max) {
			display: block;
			font-size: 21px;
			position: absolute;
			top: 100%;
			width: 100%;
			z-index: 1;
		}

		nav{
			width: 100%;

			@include media-min($desktop-min) {
				width: auto;
			}

			ul{
				justify-content: space-between;

				@include media-min($desktop-min) {
					justify-content: flex-start;
				}
			}

			& > ul > li {
				&:first-child {
					& > a {
						padding-left: 0;
					}
				}

				&:last-child {
					& > a {
						padding-right: 0;
					}
				}

				&:nth-child(n + 2)::before {
					background-color: rgba(255, 255, 255, .3);
					content: '';
					display: block;
					height: 16px;
					left: 0;
					position: absolute;
					top: calc(50% - 8px);
					width: 1px;
				}
			}
		}

		li {
			@include media($desktop-min) {
				text-align: center;
				width: 100%;
				white-space: nowrap;
			}
		}

		a {
			color: #fff;
			padding: 36px 38px;

			&.current_page_item {
				background: $action-color-light;
				color:#000;
				display:block;
			}

			@include media($desktop-extra-min) {
				padding: 36px 20px;
			}

		}

		p {
			color: #f2f2f2;
			font-size: 22px;
			font-family: 'Trade Gothic LT W01 Light', sans-serif;
			font-weight: lighter;
		}

		ul.sub-menu {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: opacity ease-in-out .2s;

			background: #746f6b;
			font-size: 0.8em;
			min-width: 100%;
			white-space: nowrap;

			li {
				a {
					padding: 20px 30px;
				}
			}
		}

		li:hover > ul.sub-menu {
			visibility: visible;
			opacity: 1;
		}
	}

	&-footer {
		font-size: 21px;
		white-space: nowrap;

		@include media($desktop-min) {
			order: 1;
			margin-bottom: 40px;
			white-space: normal;
		}

		ul{
			display: flex;
			flex-direction: column;

			@include media-min($mobile-max) {
				flex-direction: row;
			}
		}

		li{
			padding: 0 23px;

			@include media($desktop-extra-min) {
				padding: 0 10px;
			}

			@include media($tablet-min) {
				padding: 0;
			}
		}

		a {
			padding-bottom: 12px;
			padding-top: 12px;
		}

		ul.sub-menu {
			display: none;
		}
	}

	a {
		display: inline-block;
		font-family: 'Trade Gothic LT W01 Bd CnNo-20', sans-serif;
		letter-spacing: 1px;
		line-height: 1;
		text-transform: uppercase;

		@include media($desktop-extra-min) {
			font: 18px/21px 'Trade Gothic LT W01 Bd CnNo-20', sans-serif;
		}

		@include media($desktop-min) {
			font: 21px/21px 'Trade Gothic LT W01 Bd CnNo-20', sans-serif;
		}
	}
}
