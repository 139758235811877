// Placeholder color
@mixin placeholder-color($color) {
	&:-moz-placeholder {
		color: $color !important;
		opacity: 1 !important;
	}
	&::-moz-placeholder {
		color: $color !important;
		opacity: 1 !important;
	}
	&:-ms-input-placeholder {
		color: $color !important;
		opacity: 1 !important;
	}
	&::-webkit-input-placeholder {
		color: $color !important;
		opacity: 1 !important;
	}

	&:focus:-moz-placeholder {
		color: transparent !important;
	}
	&:focus::-moz-placeholder {
		color: transparent !important;
	}
	&:focus:-ms-input-placeholder {
		color: transparent !important;
	}
	&:focus::-webkit-input-placeholder {
		color: transparent !important;
	}
}

// Media queries
@mixin media($size) {
	@media (max-width: $size) {
		@content;
	}
}

@mixin media-min($size) {
	@media (min-width: $size) {
		@content;
	}
}

@mixin desktop() {
	@media (min-width: $desktop-min) {
		@content;
	}
}

@mixin tablet-ls() {
	@media (max-width: $tablet-ls-max) {
		@content;
	}
}

@mixin tablet() {
	@media (max-width: $tablet-max) {
		@content;
	}
}

@mixin iphone-ls() {
	@media (max-width: $iphone-ls-max) {
		@content;
	}
}

@mixin iphone() {
	@media (max-width: $iphone-max) {
		@content;
	}
}

@mixin mobile() {
	@media (max-width: $mobile-max) {
		@content;
	}
}