.footer-main {
	display: flex;
	flex-direction: column;
	padding: 40px 20px 60px;
	text-align: center;
	width: 100%;

	@include media-min($mobile-max) {
		padding: 40px 0 60px;
	}

	@include media-min($desktop-min) {
		flex-direction: row;
		padding: 40px 0 60px;
		text-align: left;
	}

	&.gold {
		padding-top: 35px;
		position: relative;

		&:before{
			background: #e1b86d;
			content: '';
			left: 0;
			height: 250px;
			position: absolute;
			right: 0;
			top: 0;

			@include media-min($mobile-max) {
				height: 115px;
			}
		}
		
		hr{
			border: none;
			margin: 35px 0 0;

			@include media-min($desktop-min) {
				margin-top: 35px;
				margin-bottom: 30px;
			}
		}
	}

	&-logo {
		width: 164px;

		@include media($desktop-min) {
			height: 37px;
			margin: auto auto 18px;
			order: 3;
		}

		@include media($tablet-min) {
			width: auto;
		}

		img{
			max-width: 164px;
		}
	}

	&-phone {
		font-size: 19px;
		min-width: 170px;
		padding-bottom: 9px;
		padding-top: 9px;

		@include media($desktop-min) {
			font-size: 14px;
			margin: 0 0 15px;
			order: 5;
			text-align: center !important;
		}
	}

	.footer-disclaimer {
		font-size: 14px;

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.copyright,
	.privacy-policy,
	.disclaimer {
		font-size: 14px;

		@include media($desktop-min) {
			order: 6;
		}
	}

	.address {
		font-size: 14px;

		@include media($desktop-min) {
			margin: 0;
			order: 4;
		}
	}

	.privacy-policy {
		margin-left: auto;

		@include media($desktop-min) {
			margin: 0;
			text-align: center !important;
		}
	}

	.disclaimer {
		margin-top: 35px;
	}

	a {
		color: $default-color-text;
	}

	hr {
		background-color: #bfbfbf;
		margin-bottom: 30px;
		margin-top: 0;
		min-width: 100%;
		order: 2;

		@include media-min($desktop-min) {
			margin-bottom: 30px;
			margin-top: 40px;
			order: 0;
		}
	}
}
