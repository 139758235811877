.video {
	max-width: 900px;
	margin: 0 auto 70px;
	text-align: center;

	&-container {
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}